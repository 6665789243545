// Import package from Soil plugin example.
import $ from "@modules/jquery";
import { Tooltip } from "@modules/foundation-sites/js/foundation.tooltip";
Foundation.plugin(Tooltip, "Tooltip");

$(function () {
  $.ajax({
    type: "POST",
    dataType: "json",
    url: frameworkGlobal.ajaxurl,
    data: {
      action: "get_opening_hours",
    },
    success: function (response) {
      $(".opening-hours").html(response.data);
    },
  });
});

$(window).on("load", function () {
  const nav = document.querySelector("#flexible-rearrangeable-elements-row-2");
  if (nav) {
    let position = 0;
    window.addEventListener("scroll", function () {
      const height = nav.offsetHeight;
      let scrollY = window.scrollY;
      if (scrollY > position && position > 0) {
        nav.classList.add("hide");
        nav.style.marginTop = -height + "px";
      } else {
        nav.classList.remove("hide");
        nav.style.removeProperty("margin-top");
      }
      position = scrollY;
    });
  }

  $("li.mega-menu-megamenu").on("open_panel", function () {
    setTimeout(() => {
      $("#content, #footer").addClass("mega-menu-open");
    }, 200);
  });

  $("li.mega-menu-megamenu").on("close_panel", function () {
    $("#content, #footer").removeClass("mega-menu-open");
  });
});
function equalizeHeight(container) {
  let currentTallest = 0;
  let currentRowStart = 0;
  const rowDivs = new Array();
  let topPosition = 0;
  $(container).each(function () {
    const $el = $(this);
    $($el).height("auto");
    topPosition = $el.position().top;

    if (currentRowStart !== topPosition) {
      for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
        rowDivs[currentDiv].height(currentTallest);
      }
      rowDivs.length = 0; // empty the array
      currentRowStart = topPosition;
      currentTallest = $el.height();
      rowDivs.push($el);
    } else {
      rowDivs.push($el);
      currentTallest =
        currentTallest < $el.height() ? $el.height() : currentTallest;
    }
    for (let currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
      rowDivs[currentDiv].height(currentTallest);
    }
  });
}
$(document).ready(function () {
  // Select the target node.
  const coursesGrid = document.querySelector(
    ".wpgb-content-cards, .wpgb-content-posts, .wpgb-content-knowlege-base"
  );
  const screenSize = $(window).width();
  if (screenSize > 600) {
    if (coursesGrid !== null) {
      // Create an observer instance.
      const observer = new MutationObserver(async function (mutations) {
        for (const mutation of mutations) {
          const node = mutation.target;
          if (node.className.includes("wpgb-loading")) {
            continue;
          }

          await new Promise((r) => setTimeout(r, 100));

          const grid = $(
            ".wpgb-content-cards .wpgb-card, .wpgb-content-post .wpgb-card, .wpgb-content-knowlege-base .wpgb-card"
          );
          console.log(grid);
          new Foundation.Equalizer(grid);

          grid.each(function () {
            const details = $(this).find(".wpgb-card-wrapper");
            equalizeHeight(details);
          });
        }
      });

      // Pass in the target node, as well as the observer options.
      observer.observe(coursesGrid, {
        attributes: true,
      });
    }
  }
  // const allProductSliders = document.querySelectorAll(".ms_products_slider");
  //   for (const slider of allProductSliders) {
  //     let options = slider.querySelector(".product-slider").dataset.sliderOptions;
  //     options = JSON.parse(options);
  //     console.log(slider.id, options)

  //     const productSlider = new window.Swiper(
  //         `#${slider.id} .product-slider`,
  //         options
  //     );
  // }

  // Initialize product sliders.
  const productSliders = [];
  const allProductSliders = document.querySelectorAll(".ms_products_slider");
  for (const sliders of allProductSliders) {
    if (sliders.querySelector(".product-slider")) {
      productSliders.push({
        id: sliders.id,
        main: sliders.querySelector(".product-slider"),
        thumb: null,
        selector: "product-slider",
      });
    }
    if (sliders.querySelector(".product-mobile-slider")) {
      productSliders.push({
        id: sliders.id,
        main: sliders.querySelector(".product-mobile-slider"),
        thumb: null,
        selector: "product-mobile-slider",
      });
    }
  }

  const allSubcategorySliders = document.querySelectorAll(
    ".woocommerce-subcategory-slider"
  );
  for (const sliders of allSubcategorySliders) {
    if (sliders.querySelector(".subcategory-slider")) {
      productSliders.push({
        id: sliders.id,
        main: sliders.querySelector(".subcategory-slider"),
        thumb: null,
        selector: "subcategory-slider",
      });
    }
  }

  const allProductCategorySliders = document.querySelectorAll(
    ".woocommerce-product-category-slider"
  );
  for (const sliders of allProductCategorySliders) {
    if (sliders.querySelector(".product-category-slider")) {
      productSliders.push({
        id: sliders.id,
        main: sliders.querySelector(".product-category-slider"),
        thumb: null,
        selector: "product-category-slider",
      });
    }
  }

  if (productSliders.length !== 0) {
    for (const slider of productSliders) {
      window.initializeSliders(slider);
    }
  }

  const singleProductGrid = $(".single-product .products");
  const productGridJQuery = $(".wpgb-content-producten");

  if (screenSize > 600) {
    if (singleProductGrid.length) {
      singleProductGrid.each(function () {
        const title = $(this).find(".woocommerce-loop-product__title");
        const productMeta = $(this).find(".meta");
        equalizeHeight(title);
        equalizeHeight(productMeta);
      });
    }

    if (productGridJQuery.length) {
      productGridJQuery.each(function () {
        const title = $(this).find(".woocommerce-loop-product__title");
        const productMeta = $(this).find("meta");
        equalizeHeight(title);
        equalizeHeight(productMeta);
      });
    }
  }
  //column order on mobile
  const mediaQuery = setInterval(() => {
    if (Foundation.MediaQuery.queries.length === 0) {
      return;
    }

    if (Foundation.MediaQuery.current !== "") {
      clearInterval(mediaQuery);
    }

    if (Foundation.MediaQuery.upTo("sm")) {
      const columns = document.querySelectorAll(".wp-block-column");
      columns.forEach((column) => {
        column.style.order = "1";
        if (
          column.querySelectorAll(".wp-block-cover, .wp-block-image").length > 0
        ) {
          column.style.order = "2";
        }
      });
    }
  });

  jQuery(window).on(
    "changed.zf.mediaquery",
    function (event, newSize, oldSize) {
      if (Foundation.MediaQuery.queries.length === 0) {
        return;
      }
      if (Foundation.MediaQuery.upTo("sm")) {
        const columns = document.querySelectorAll(".wp-block-column");
        columns.forEach((column) => {
          column.style.order = "1";
          if (
            column.querySelectorAll(".wp-block-cover, .wp-block-image").length >
            0
          ) {
            column.style.order = "2";
          }
        });
      } else {
        const columns = document.querySelectorAll(".wp-block-column");
        columns.forEach((column) => {
          column.style.order = "1";
        });
      }
    }
  );
});

function equalColumns() {
  let screenSize = $(window).width();
  $(".equal-columns").each(function (index) {
    $(this).attr("data-equalizer", `equal-columns-${index}`);
    const column = $(this).find(".wp-block-column");
    column.attr("data-equalizer-watch", `equal-columns-${index}`);
    const wpsoupCard = $(this).find(
      ".wp-block-column .card,.wp-block-column .swiper-slide .card"
    );
    console.log(wpsoupCard);
    equalizeHeight(wpsoupCard);
    wpsoupCard.attr("data-equalizer-watch", `equal-columns-${index}`);
  });
}
window.addEventListener("resize", equalColumns);
$(window).on("load", function () {
  equalColumns();
  window.addEventListener("resize", equalColumns);
});

$(window).on("resize", function () {
  equalColumns();
});

$(document).on("yith_wccl_product_gallery_loaded", function () {
  if ($(".woocommerce .product-image-slider").length) {
    const galleryThumbs = new window.Swiper(
      ".woocommerce .product-thumbnail-slider",
      {
        slidesPerView: 3,
        spaceBetween: "16",
        freeMode: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: true,
        },
        breakpoints: {
          640: {
            slidesPerView: 3,
          },
          1024: {
            slidesPerView: 5,
          },
          1280: {
            slidesPerView: 6,
          },
        },
      }
    );

    if ($(".woocommerce .product-thumbnail-slider").length) {
      const galleryTop = new window.Swiper(
        ".woocommerce .product-image-slider",
        {
          loop: true,
          slidesPerView: 1,
          autoHeight: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          thumbs: {
            swiper: galleryThumbs,
          },
        }
      );
    }

    $(".swiper-button-next, .swiper-button-prev").on("mousedown", function (e) {
      e.preventDefault();
    });
  }
});

$(handleProductFilters); // On page load.
$(document).on("yith-wcan-ajax-filtered", function () {
  handleProductFilters(); // After filtering.
});
function handleProductFilters() {
  $(".yith-wcan-filter").each(function () {
    const content = $(this).find(".filter-content.has-hidden-items");
    const items = content.find(".filter-item.toggleable");
    $(this).on("click", ".toggle.show-all", function () {
      items.toggleClass("hidden");
      $(this).toggleClass("hidden");
      $(this).parent().find(".toggle.show-less").toggleClass("hidden");
    });
    $(this)?.on("click", ".toggle.show-less", function () {
      items.toggleClass("hidden");
      $(this).toggleClass("hidden");
      $(this).parent().find(".toggle.show-all").toggleClass("hidden");
    });
  });
}

(() => {
  const readingIndicators = document.getElementsByClassName(
    "js-reading-progressbar"
  );
  const readingIndicatorContent =
    document.getElementsByClassName("site-content")[0];

  [...readingIndicators].forEach((readingIndicator) => {
    if (readingIndicator && readingIndicatorContent) {
      const progressInfo = [];
      let progressEvent = false;
      const progressFallback = readingIndicator.getElementsByClassName(
        "js-reading-progressbar__fallback"
      )[0];
      const progressIsSupported = "value" in readingIndicator;

      const boundingClientRect =
        readingIndicatorContent.getBoundingClientRect();
      progressInfo["height"] = readingIndicatorContent.offsetHeight;
      progressInfo["top"] = boundingClientRect.top;
      progressInfo["bottom"] = boundingClientRect.bottom;
      progressInfo["window"] = window.innerHeight;
      progressInfo["class"] = "reading-progressbar--is-active";
      progressInfo["hideClass"] = "reading-progressbar--is-out";

      // init indicator
      setProgressIndicator();
      // wait for font to be loaded - reset progress bar
      if (document.fonts) {
        document.fonts.ready.then(function () {
          triggerReset();
        });
      }
      // listen to window resize - update progress
      window.addEventListener("resize", function (event) {
        triggerReset();
      });

      //listen to the window scroll event - update progress
      window.addEventListener("scroll", function (event) {
        if (progressEvent) return;
        progressEvent = true;
        !window.requestAnimationFrame
          ? setTimeout(function () {
              setProgressIndicator();
            }, 250)
          : window.requestAnimationFrame(setProgressIndicator);
      });

      function setProgressIndicator() {
        var boundingClientRect =
          readingIndicatorContent.getBoundingClientRect();
        progressInfo["top"] = boundingClientRect.top;
        progressInfo["bottom"] = boundingClientRect.bottom;
        if (progressInfo["height"] <= progressInfo["window"]) {
          // short content - hide progress indicator
          readingIndicator.classList.remove(progressInfo["class"]);
          progressEvent = false;
          return;
        }
        // get new progress and update element
        readingIndicator.classList.add(progressInfo["class"]);
        const value =
          progressInfo["top"] >= 0
            ? 0
            : (100 * (0 - progressInfo["top"])) /
              (progressInfo["height"] - progressInfo["window"]);
        readingIndicator.setAttribute("value", value);
        if (!progressIsSupported && progressFallback)
          progressFallback.style.width = `${value}%`;
        // hide progress bar when target is outside the viewport
        toggleClass(
          readingIndicator,
          progressInfo["hideClass"],
          progressInfo["bottom"] <= 0
        );
        progressEvent = false;
      }

      function triggerReset() {
        if (progressEvent) return;
        progressEvent = true;
        !window.requestAnimationFrame
          ? setTimeout(function () {
              resetProgressIndicator();
            }, 250)
          : window.requestAnimationFrame(resetProgressIndicator);
      }

      function resetProgressIndicator() {
        progressInfo["height"] = readingIndicatorContent.offsetHeight;
        progressInfo["window"] = window.innerHeight;
        setProgressIndicator();
      }

      function toggleClass(el, className, bool) {
        if (bool) el.classList.add(className);
        else el.classList.remove(className);
      }
    }
  });
})();
